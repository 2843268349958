import React, { Component } from 'react';
import './App.css';
import * as tf from '@tensorflow/tfjs';
import logo from './logo.png';

const MODEL = "https://assets.rustcamera.com/model.json"

async function loadTruncatedMobileNet() {
  const model = await tf.loadLayersModel(MODEL)
  const layer = model.getLayer('output_layer')
  return tf.model({inputs: model.inputs, outputs: layer.output});
}

class App extends Component {

  constructor(props) {
    super(props)
    this.videoRef = React.createRef()
    this.canvasRef = React.createRef()
    this.state = {probability: 0, isPaused: false,
      scoreCount: 0, frameCount: 0,
      cameraLabel: null,
      isScoring: false, logs: ''}

    var console = {}
    console.log = (e) => this.appendLog('log', e)
    console.warn = (e) => this.appendLog('warn', e)
    console.error = (e, data) => this.appendLog('error', e)
    console.debug = (e, data) => this.appendLog('debug', e)
    window.console = console

  }

  captureFrame() {
    return tf.tidy(() => {
      // read and resize image
      const webcamImage = tf.browser.fromPixels(this.videoRef.current)
      const croppedImage =  this.cropImage(webcamImage, 224)
      //tf.image.resizeBilinear(webcamImage, [224, 224])
      const batchedImage = croppedImage.expandDims(0)
      return batchedImage
    });
  }

  appendLog = (etype, e, data=null) => {
    let cLog = this.state.logs
    cLog = cLog + '[' + etype + '] ' + e + "\n"
    if (data !== null) {
      cLog = cLog + '[' + etype + '] ' + data + "\n"
    }
    this.setState({logs: cLog})
  }

  async getVideoInputs() {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.log('enumerateDevices() not supported.');
      return [];
    }
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(device => device.kind === 'videoinput');
    return videoDevices;
  }

  async getDeviceIdForLabel(cameraLabel) {
    const videoInputs = await this.getVideoInputs();

    for (let i = 0; i < videoInputs.length; i++) {
      const videoInput = videoInputs[i];
      if (videoInput.label === cameraLabel) {
        return videoInput.deviceId;
      }
    }

    return null;
  }

  isAndroid() {
    return /Android/i.test(navigator.userAgent);
  }

  isiOS() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  isMobile() {
    return this.isAndroid() || this.isiOS();
  }

  getFacingMode(cameraLabel) {
    if (!cameraLabel) {
      return 'user';
    }
    if (cameraLabel.toLowerCase().includes('back')) {
      return 'environment';
    } else {
      return 'user';
    }
  }

  async getConstraints(cameraLabel) {
    let deviceId;
    let facingMode;

    if (cameraLabel) {
      deviceId = await this.getDeviceIdForLabel(cameraLabel);
      facingMode = this.isMobile() ? this.getFacingMode(cameraLabel) : null
    }
    return {deviceId, facingMode}
  }

  async componentDidMount() {
    console.log(navigator.userAgent)
    this.model = await loadTruncatedMobileNet()
    console.log('loading camera..');
    const devices = await navigator.mediaDevices.enumerateDevices()
    devices.forEach(function(device) {
      if (device.kind === 'videoinput') {
        this.cameraDeviceId = device.deviceId
        console.log('found camera = ' + device.label)
        console.log('found device id = ' + device.deviceId)
        console.log('facing mode = ' + device.facingMode)
        console.log(Object.keys(device))
        console.log(device.kind)
      } 
    }, this)

    console.log('using camera = ' + this.cameraDeviceId)
    const videoElement = this.videoRef.current
    const videoConstraints = await this.getConstraints(this.cameraDeviceId)
    const stream = await navigator.mediaDevices.getUserMedia(
      {video: {deviceId: this.cameraDeviceId}})
    videoElement.srcObject = stream
    videoElement.onloadedmetadata = function(e) { videoElement.play() }
    setInterval(() => this.boop(stream), 100)
  }

  async boop(stream) {
    if (document.readyState === "complete" & this.state.isScoring === false) {
      this.scoreImage()
    }
  }

  async scoreImage() {
    if(this.model === undefined) { return }
      // this is a hack to make TF happy with the video element
      this.videoRef.current.width = this.videoRef.current.videoWidth
      this.videoRef.current.height = this.videoRef.current.videoHeight

      if (this.videoRef.current.height === 0) { return }

      //const img = tf.browser.fromPixels(this.videoRef.current)
      const img = this.captureFrame()
      tf.browser.toPixels(tf.squeeze(img), this.refs.canvasRef)
        .catch((err) => { console.log(err) })

      let frameCount = this.state.frameCount
      this.setState({frameCount: frameCount + 1})

      if (this.state.isPaused === false) {
        this.setState({isScoring: true})
        const pre = this.model.predict(img)
        pre.data().then((result) => {
          let rustScore = result[1].toFixed(5)
          let scoreCount = this.state.scoreCount
          this.setState({probability: rustScore, 
            isScoring: false, scoreCount: scoreCount + 1})
        })
      }
  }

  cropImage(img, size) {
    const centerHeight = img.shape[0] / 2;
    const beginHeight = centerHeight - (size / 2);
    const centerWidth = img.shape[1] / 2;
    const beginWidth = centerWidth - (size / 2);
    return img.slice([beginHeight, beginWidth, 0], [size, size, 3]);
  }

  crash = () => {
    throw new Error("woah!")
  }

  pauseScoring = () => {
    console.log("pausing scoring..")
    this.setState({isPaused: true})   
  }

  render() {
    return (
      <div style={styles.container}>
        <img style={styles.logo} src={logo} alt="logo" />
        <video 
          style={styles.vidboxNew}
          autoPlay
          ref={this.videoRef} playsInline />
        <canvas height={224} width={224} style={styles.canvasRef} ref='canvasRef'></canvas>
        <div style={styles.scoreBox}>
          Probabilty of Corrosion: {this.state.probability}
        </div>
        <button style={styles.pauseButton} onClick={this.pauseScoring} >Pause Scoring</button>

        <button style={styles.pauseButton} onClick={this.crash}>Crash</button>

        <pre>Version: {process.env.REACT_APP_VERSION}</pre>
        <pre>Debug: {this.state.frameCount} frames / {this.state.scoreCount} scored </pre>
        <pre>{this.state.logs}</pre>
      </div>
    );
  }
}

var styles = {
  container: {
    padding: '0',
  },
  vidbox: { 
    maxWidth: '100%',
    width: '224px',
    height: '224px',
    maxHeight: '224px',
    padding: '0',
    border: '1px solid red',
    objectFit: 'cover'
  },
  canvasRef: {
  },
  vidboxNew: {
    display: 'none'
  },
  scorebox: {
    color: 'white'
  },
  logo: {
    maxWidth: '100%',
    width: '70vw',
    display: 'block',
    padding: '8px'
  },
  pauseButton: {
    borderRadius: '10px',
    padding: '8px',
    color: 'white',
    backgroundColor: '#bd2130',
    borderColor: '#b21f2d'
  }
}


export default App;
